import { Lato } from 'next/font/google'

export const LatoFont = Lato({
  weight: ['100', '300', '400', '700', '900'],
  adjustFontFallback: true,
  display: 'swap',
  preload: true,
  style: 'normal',
  subsets: ['latin'],
  variable: '--lato-font',
})
