import React, { FC } from 'react'

// MUI
import { Box, Container, Divider, Grid, Stack, Tooltip } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import CallIcon from '@mui/icons-material/Call'
import InstagramIcon from '@mui/icons-material/Instagram'

// Constants
import { CONSTANTS } from '@/constants'

// UI
import { BlackNextLink, PaddTwentyFour } from '@/ui'
import { CopyRightText, CustomLI, CustomPicDiv, CustomUL, FooterLinkText, FooterLinkTextTwo, FooterSectionTitle, LogoSocialMediaDiv } from './styles'

const MainFooter: FC = () => {
  return (
    <Box sx={{ py: theme => theme.typography.pxToRem(30) }}>
      <Container maxWidth="xl">
        <PaddTwentyFour component="section">
          <Box>
            <Grid container justifyContent="space-between" spacing={4}>
              <Grid item md={3} sm={6} xs={12}>
                <CustomPicDiv>
                  <picture>
                    <img src="/logo/comfort_main_cropped.png" alt={CONSTANTS.APP_NAME} title={CONSTANTS.APP_NAME} />
                  </picture>
                </CustomPicDiv>

                <LogoSocialMediaDiv>
                  <BlackNextLink
                    href="https://instagram.com/comfort_the_home_craft"
                    aria-label={`Follow ${CONSTANTS.APP_NAME} on Instagram`}
                    aria-labelledby={`Follow ${CONSTANTS.APP_NAME} on Instagram`}
                    target="_blank"
                  >
                    <Tooltip title="Follow on Instagram" arrow>
                      <InstagramIcon />
                    </Tooltip>
                  </BlackNextLink>
                </LogoSocialMediaDiv>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FooterSectionTitle>Contact Us</FooterSectionTitle>

                <CustomUL>
                  <CustomLI>
                    <Stack direction="row" gap={1}>
                      <EmailIcon sx={{ color: '#6a6a8e' }} />

                      <FooterLinkText>
                        Email
                        <FooterLinkText>
                          <BlackNextLink href="mailto:zishansameja1@gmail.com" aria-label="zishansameja1@gmail.com" target="_blank" role="link" sx={{ color: '#6a6a8e' }}>
                            zishansameja1@gmail.com
                          </BlackNextLink>
                        </FooterLinkText>
                      </FooterLinkText>
                    </Stack>
                  </CustomLI>
                  <CustomLI>
                    <Stack direction="row" gap={1}>
                      <CallIcon sx={{ color: '#6a6a8e' }} />

                      <FooterLinkText>
                        Phone
                        <FooterLinkText>
                          <BlackNextLink href="tel:+917567371612" aria-label="+91 - 756 737 1612" role="link" target="_blank" sx={{ color: '#6a6a8e' }}>
                            +91 - 756 737 1612
                          </BlackNextLink>
                        </FooterLinkText>
                      </FooterLinkText>
                    </Stack>
                  </CustomLI>
                </CustomUL>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FooterSectionTitle>Company</FooterSectionTitle>

                <Box>
                  <FooterLinkTextTwo>
                    <BlackNextLink href="/contact" aria-label="Contact Comfort - The Home Craft" aria-labelledby="Contact Comfort - The Home Craft" role="link" sx={{ color: '#6a6a8e' }}>
                      Contact
                    </BlackNextLink>
                  </FooterLinkTextTwo>
                  <FooterLinkTextTwo>
                    <BlackNextLink href="/services" aria-label="Services of Comfort - The Home Craft" aria-labelledby="Services of Comfort - The Home Craft" role="link" sx={{ color: '#6a6a8e' }}>
                      Services
                    </BlackNextLink>
                  </FooterLinkTextTwo>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ my: theme => theme.typography.pxToRem(30), border: '1px solid #6a6a8e' }} />

          <Box>
            <Grid container justifyContent="space-between" alignItems="center" spacing={{ md: 4, xs: 2 }}>
              <Grid item>
                <CopyRightText>&copy; {CONSTANTS.APP_NAME}. All Rights reserved.</CopyRightText>
              </Grid>
              <Grid item>
                <Stack direction="row" alignItems="center" gap={1}>
                  <CopyRightText>Website Developed &amp; Maintained by </CopyRightText>
                  <BlackNextLink
                    href="https://mediateck.vercel.app"
                    target="_blank"
                    aria-label="Media Teck"
                    title="Media Teck"
                    sx={{ textDecoration: 'underline', '&:hover': { textDecoration: 'underline' } }}
                  >
                    Media Teck
                  </BlackNextLink>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </PaddTwentyFour>
      </Container>
    </Box>
  )
}

export default MainFooter
