import { createSvgIcon } from '@mui/material'

export const WindowBlindsIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <g>
      <g>
        <path d="M490.667,0H448h-64H128H64H21.333C9.551,0,0,9.551,0,21.333c0,11.782,9.551,21.333,21.333,21.333h21.333v448 C42.667,502.449,52.218,512,64,512h64c11.782,0,21.333-9.551,21.333-21.333v-21.333h213.333v21.333 c0,11.782,9.551,21.333,21.333,21.333h64c11.782,0,21.333-9.551,21.333-21.333v-448h21.333c11.782,0,21.333-9.551,21.333-21.333 C512,9.551,502.449,0,490.667,0z M149.333,362.667v-256h85.333v256H149.333z M277.333,256h21.333 c11.782,0,21.333-9.551,21.333-21.333c0-11.782-9.551-21.333-21.333-21.333h-21.333V106.667h85.333v256h-85.333V256z M362.667,64 H149.333V42.667h213.333V64z M106.667,469.333H85.333V42.667h21.333v42.667V384v64V469.333z M149.333,426.667v-21.333h213.333 v21.333H149.333z M426.667,469.333h-21.333V448v-64V85.333V42.667h21.333V469.333z" />
      </g>
    </g>
  </svg>,
  'WindowBlindsIcon',
)
