import { ReactNode } from 'react'

// SVG Icons
import { ArtificialGrassIcon, CurtainsIcon, MattressIcon, SofaIcon, WallPaperIcon, WindowBlindsIcon } from '@/icons'

type HomeOurServiceProps = {
  icon: ReactNode
  title: string
  desc: string
}

export const HomeOurServiceData: HomeOurServiceProps[] = [
  {
    icon: <ArtificialGrassIcon fontSize="medium" />,
    title: 'Artificial Grass',
    desc: 'Artificial Grass',
  },
  {
    icon: <CurtainsIcon fontSize="medium" />,
    title: 'Curtains',
    desc: 'Curtains',
  },
  {
    icon: <MattressIcon fontSize="medium" />,
    title: 'Mattress',
    desc: 'Mattress',
  },
  {
    icon: <SofaIcon fontSize="medium" />,
    title: 'Sofa',
    desc: 'Sofa',
  },
  {
    icon: <WallPaperIcon fontSize="medium" />,
    title: 'Wallpapers',
    desc: 'Wallpapers',
  },
  {
    icon: <WindowBlindsIcon fontSize="medium" />,
    title: 'Window Blinds',
    desc: 'Window Blinds',
  },
]
