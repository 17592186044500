import { Box, styled } from '@mui/material'

export const NavLogoDiv = styled(Box)(({ theme }) => ({
  '& img': {
    width: 'auto',

    [theme.breakpoints.between('xs', 'sm')]: {
      height: theme.typography.pxToRem(50),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.typography.pxToRem(70),
    },
  },
}))
