import React, { FC, useState } from 'react'

// MUI
import { Box, Toolbar } from '@mui/material'

// Desktop Navbar
import DesktopNavbar from './responsive/desktop'

// Mobile Navbar
import MobileNavbar from './responsive/mobile'

const MainNavbar: FC = () => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false)

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

  return (
    <Box>
      <DesktopNavbar handleDrawerToggle={handleDrawerToggle} />
      <MobileNavbar handleDrawerToggle={handleDrawerToggle} mobileOpen={mobileOpen} />

      <Box component="main">
        <Toolbar />
      </Box>
    </Box>
  )
}

export default MainNavbar
