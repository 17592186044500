type ServicesProps = {
  imgPath: string
  title: string
  desc: string
}

export const ServicesData: ServicesProps[] = [
  {
    desc: '',
    imgPath: '/pages/services/artificial_grass.webp',
    title: 'Artifical Grass',
  },
  {
    desc: '',
    imgPath: '/pages/services/curtains.webp',
    title: 'Curtains',
  },
  {
    desc: '',
    imgPath: '/pages/services/mattress.avif',
    title: 'Mattress',
  },
  {
    desc: '',
    imgPath: '/pages/services/Sofa_and_Couches.webp',
    title: 'Sofa & Couches',
  },
  {
    desc: '',
    imgPath: '/pages/services/Chair_and_Armchairs.avif',
    title: 'Chair & Armchairs',
  },
  {
    desc: '',
    imgPath: '/pages/services/tables.avif',
    title: 'Tables',
  },
  {
    desc: '',
    imgPath: '/pages/services/wallpaper.webp',
    title: 'Wallpapers',
  },
  {
    desc: '',
    imgPath: '/pages/services/window_blinds.webp',
    title: 'Window Blinds',
  },
]
