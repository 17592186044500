import { styled } from '@mui/material'

// Font
import { LatoFont } from '../fonts'

export const NavbarTypography = styled('span')(({ theme }) => ({
  fontFamily: LatoFont.style.fontFamily,
  fontStyle: LatoFont.style.fontStyle,
  color: '#000',
  fontSize: theme.typography.pxToRem(18),
  lineHeight: '1.6em',
  letterSpacing: '0px',
}))

export const TypographyH1 = styled('h1')({
  fontFamily: LatoFont.style.fontFamily,
  fontStyle: LatoFont.style.fontStyle,
})

export const TypographyH2 = styled('h2')({
  fontFamily: LatoFont.style.fontFamily,
  fontStyle: LatoFont.style.fontStyle,
})

export const TypographyH3 = styled('h3')({
  fontFamily: LatoFont.style.fontFamily,
  fontStyle: LatoFont.style.fontStyle,
})

export const TypographyH4 = styled('h4')({
  fontFamily: LatoFont.style.fontFamily,
  fontStyle: LatoFont.style.fontStyle,
})

export const TypographyH5 = styled('h5')({
  fontFamily: LatoFont.style.fontFamily,
  fontStyle: LatoFont.style.fontStyle,
})

export const TypographyH6 = styled('h6')({
  fontFamily: LatoFont.style.fontFamily,
  fontStyle: LatoFont.style.fontStyle,
})

export const TypographyP = styled('p')({
  fontFamily: LatoFont.style.fontFamily,
  fontStyle: LatoFont.style.fontStyle,
})

export const TypographySpan = styled('span')({
  fontFamily: LatoFont.style.fontFamily,
  fontStyle: LatoFont.style.fontStyle,
})
