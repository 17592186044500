import { Box, styled } from '@mui/material'

// Global UI
import { LatoFont, TypographyP } from '@/ui'

export const CustomPicDiv = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between('xs', 'sm')]: {
    textAlign: 'center',
  },
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
  },

  '& img': {
    width: theme.typography.pxToRem(250),
    height: 'auto',
  },
}))

export const LogoSocialMediaDiv = styled(Box)(({ theme }) => ({
  margin: `${theme.typography.pxToRem(15)} 0 0 ${theme.typography.pxToRem(5)}`,

  [theme.breakpoints.between('xs', 'sm')]: {
    textAlign: 'center',
  },

  '& .MuiSvgIcon-root': {
    color: 'red',
    fontSize: theme.typography.pxToRem(36),
  },
}))

export const FooterSectionTitle = styled('p')(({ theme }) => ({
  fontFamily: LatoFont.style.fontFamily,
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 500,
  lineHeight: theme.typography.pxToRem(36),
  margin: `0 0 ${theme.typography.pxToRem(30)} 0`,
  color: '#050748',
}))

export const CustomUL = styled('ul')({
  listStyle: 'none',
  margin: 0,
  padding: 0,
})

export const CustomLI = styled('li')(({ theme }) => ({
  margin: `${theme.typography.pxToRem(10)} 0`,
}))

export const FooterLinkText = styled('span')(({ theme }) => ({
  fontFamily: LatoFont.style.fontFamily,
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 600,
  lineHeight: theme.typography.pxToRem(25),
  margin: 0,
  padding: 0,
  color: '#6a6a8e',

  '& span': {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 400,
    color: '#6a6a8e',
    display: 'flex',
  },
}))

export const FooterLinkTextTwo = styled('p')(({ theme }) => ({
  fontFamily: LatoFont.style.fontFamily,
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 400,
  lineHeight: theme.typography.pxToRem(24),
  color: '#6a6a8e',
  margin: `${theme.typography.pxToRem(10)} 0`,
}))

export const CopyRightText = styled(TypographyP)(({ theme }) => ({
  color: '#372D2B',
  fontSize: theme.typography.pxToRem(15),
  fontWeight: 300,
  lineHeight: '1.6em',
}))
