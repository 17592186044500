// Nextjs
import Link from 'next/link'

// MUI
import { styled } from '@mui/material'

// Font
import { LatoFont } from '../fonts'

export const BlackNextLink = styled(Link)({
  textDecoration: 'none',
  color: '#000',
  fontFamily: LatoFont.style.fontFamily,

  '&:hover': {
    textDecoration: 'none',
    color: '#000',
  },
})
