type HomeWorksProps = {
  imgPath: string
  title: string
}

export const HomeWorksData: HomeWorksProps[] = [
  {
    imgPath: '/pages/works/08f643d6-6d66-4cb6-8cb4-7d6e6ffa4d29.webp',
    title: 'Bed - Mattress',
  },
  {
    imgPath: '/pages/works/25fa1909-8de4-42ce-b178-52427e4e2b28.webp',
    title: 'Sofa',
  },
  {
    imgPath: '/pages/works/260a4485-5655-48dc-8996-f5bf573aeec5.webp',
    title: 'Sofa',
  },
  {
    imgPath: '/pages/works/569914bd-4364-4f1f-803b-eb0bd0fb5a1d.webp',
    title: 'Bed - Mattress',
  },
  {
    imgPath: '/pages/works/6518d83a-2ee3-415b-8824-5941eeffe85e.webp',
    title: 'Wallpaper',
  },
  {
    imgPath: '/pages/works/68bc6dd0-854b-4686-ae00-92c8ade78a4b.webp',
    title: 'Bed - Mattress',
  },
  {
    imgPath: '/pages/works/7056bfe9-6a67-4e50-9d47-02b31c39ae13.webp',
    title: 'Wallpaper',
  },
  {
    imgPath: '/pages/works/97407750-83a7-47b8-869a-3cdf47fdd94c.webp',
    title: 'Sofa - Chair',
  },
  {
    imgPath: '/pages/works/b376ec99-ea08-421d-a0fa-5e6cfbfb812b.webp',
    title: 'Chair',
  },
  {
    imgPath: '/pages/works/bcdf36f0-0c36-4fe9-bfa4-9e681adf0a9c.webp',
    title: 'Sofa',
  },
  {
    imgPath: '/pages/works/c50c8ce2-bba7-41ee-962b-b42a678ff711.webp',
    title: 'Bed - Mattress',
  },
  {
    imgPath: '/pages/works/da2dd158-0107-4321-91fb-03255a37a39c.webp',
    title: 'Sofa',
  },
  {
    imgPath: '/pages/works/da75287b-a2c0-4062-b212-7570e9dc58ae.webp',
    title: 'Bed - Mattress',
  },
  {
    imgPath: '/pages/works/ea601e05-ef53-4c49-9228-d9f5163c5f57.webp',
    title: 'Wallpaper',
  },
  {
    imgPath: '/pages/works/f42a1662-a860-4bb8-b153-83b3d9976c67.webp',
    title: 'Sofa',
  },
]
