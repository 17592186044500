import React, { FC, ReactNode, useRef } from 'react'

// NotiStack
import { SnackbarProvider } from 'notistack'
import InfoIcon from '@mui/icons-material/Info'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'

// MUI
import { Box, Collapse, GlobalStyles, useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'

const SnackbarStyles = () => {
  const theme = useTheme()

  const isLight = theme.palette.mode === 'light'

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .SnackbarContent-root': {
            width: '100%',
            padding: theme.spacing(1),
            margin: theme.spacing(0.25, 0),
            // boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: grey[isLight ? 50 : 800],
            backgroundColor: grey[isLight ? 900 : 50],
            '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo': {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.paper,
            },
            [theme.breakpoints.up('md')]: {
              minWidth: 240,
            },
          },
          '& .SnackbarItem-message': {
            padding: 0,
            fontWeight: theme.typography.fontWeightMedium,
          },
          '& .SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,
            '& .MuiSvgIcon-root': { width: 20, height: 20 },
          },
        },
      }}
    />
  )
}

type SnackbarIconProps = {
  snackbarType: 'info' | 'success' | 'warning' | 'error'
}

const SnackbarIcon: FC<SnackbarIconProps> = props => {
  const { snackbarType } = props

  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        // height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        // color: ${color}.main,
        // bgcolor: theme => alpha(theme.palette[color].main, 0.16),
      }}
    >
      {(() => {
        switch (snackbarType) {
          case 'info':
            return <InfoIcon />
          case 'success':
            return <CheckCircleIcon />
          case 'warning':
            return <WarningIcon />
          case 'error':
            return <ErrorIcon />
          default:
            return null
        }
      })()}
    </Box>
  )
}

type Props = {
  children: ReactNode
}

const NotistackProvider: FC<Props> = props => {
  const { children } = props

  const notistackRef = useRef<any>(null)

  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        preventDuplicate
        autoHideDuration={6000}
        TransitionComponent={Collapse}
        variant="success"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <SnackbarIcon snackbarType="info" />,
          success: <SnackbarIcon snackbarType="success" />,
          warning: <SnackbarIcon snackbarType="warning" />,
          error: <SnackbarIcon snackbarType="error" />,
        }}
      >
        {children}
      </SnackbarProvider>
    </>
  )
}

export default NotistackProvider
