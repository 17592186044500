// Nextjs
import type { AppProps } from 'next/app'
import Router from 'next/router'

// nProgress
import nProgress from 'nprogress'

// Emotion
import { CacheProvider, EmotionCache } from '@emotion/react'

// MUI
import { CssBaseline, ThemeProvider } from '@mui/material'

// Snackbar Provider
import NotistackProvider from '@/components/common/notistack'

// Layout
import Layout from '@/layout'

// Create MUI THeme
import { createTheme } from '@/theme'

// Utils
import { createEmotionCache } from '@/utils'

const clientSideEmotionCache = createEmotionCache()

// Next Router + nProgress
Router.events.on('routeChangeStart', nProgress.start)
Router.events.on('routeChangeError', nProgress.done)
Router.events.on('routeChangeComplete', nProgress.done)

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

const App = (props: MyAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={createTheme({ direction: 'ltr', mode: 'light', responsiveFontSizes: true })}>
        <CssBaseline />
        <NotistackProvider>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </NotistackProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}

export default App
