import React, { FC } from 'react'

// Nextjs
import { useRouter } from 'next/router'

// MUI
import { AppBar, Box, Container, IconButton, Toolbar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

// Constants
import { CONSTANTS } from '@/constants'

// Data
import { NavbarLinkData } from '@/data'

// UI
import { BlackNextLink, NavbarTypography } from '@/ui'
import { NavLogoDiv } from '../../styles'

type DesktopNavbarProps = {
  handleDrawerToggle: () => void
}

const DesktopNavbar: FC<DesktopNavbarProps> = props => {
  const { handleDrawerToggle } = props

  const router = useRouter()

  return (
    <Box>
      <AppBar component="nav" sx={{ background: 'transparent', boxShadow: 'none', position: 'absolute' }}>
        <Container maxWidth="xl">
          <Toolbar sx={{ height: '70px', minHeight: '70px' }}>
            <IconButton aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { md: 'none' }, color: '#000' }}>
              <MenuIcon />
            </IconButton>

            <Box sx={{ flexGrow: { md: 1, xs: 0 }, ml: 'auto' }}>
              <BlackNextLink href="/" aria-label="Comfort - The Home Craft Home" aria-labelledby="Comfort - The Home Craft Home" role="link">
                <NavLogoDiv>
                  <picture>
                    <img src="/logo/comfort_main_cropped.png" alt={CONSTANTS.APP_NAME} title={CONSTANTS.APP_NAME} />
                  </picture>
                </NavLogoDiv>
              </BlackNextLink>
            </Box>

            <Box sx={{ flexGrow: 3, display: { xs: 'none', md: 'flex' }, justifyContent: { xs: 'space-between', md: 'flex-end' } }}>
              {NavbarLinkData.map((data, idx) => {
                return (
                  <NavbarTypography key={idx} sx={{ mr: idx !== NavbarLinkData.length - 1 ? 2 : 0 }}>
                    <BlackNextLink
                      href={data.link}
                      aria-label={`${data?.label} ${CONSTANTS.APP_NAME}`}
                      aria-labelledby={`${data?.label} ${CONSTANTS.APP_NAME}`}
                      role="link"
                      sx={{
                        ...(data?.link === router.pathname && {
                          color: 'var(--primary-color)',
                          textDecorationLine: 'underline',
                        }),
                      }}
                    >
                      {data.label}
                    </BlackNextLink>
                  </NavbarTypography>
                )
              })}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  )
}

export default DesktopNavbar
