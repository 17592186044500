import React, { FC } from 'react'

// MUI
import { Box, Fab } from '@mui/material'

// SVG icon
import { WhatsAppIcon } from '@/icons'

const WhatsAppFAB: FC = () => {
  const openWhatsApp = (e: { preventDefault: () => void }) => {
    e.preventDefault()

    const MobileNumber = '+917567371612'
    const WhatsAppUrl = 'https://wa.me'
    const TextMsg = 'Hello, I am reaching out to you from the Comfort - The Home Craft Website.'

    window.open(`${WhatsAppUrl}/${MobileNumber}?${TextMsg}`, '_blank')
  }

  return (
    <Box sx={{ m: 1 }}>
      <Fab
        size="medium"
        aria-label="WhatsApp FAB Button"
        sx={{ bottom: 0, margin: theme => theme.spacing(1.5), position: 'fixed', right: 0, zIndex: 1900, background: '#FFF', '&:hover': { background: '#FFF' } }}
        onClick={openWhatsApp}
      >
        <WhatsAppIcon />
      </Fab>
    </Box>
  )
}

export default WhatsAppFAB
