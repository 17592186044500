import { createTheme as createMuiTheme, responsiveFontSizes as MuiResponsiveFontSizes } from '@mui/material'

// Themes
import { baseThemeOptions } from './base-theme-options'
import { darkThemeOptions } from './dark-theme-options'
import { lightThemeOptions } from './light-theme-options'

type ThemeConfigProps = {
  mode: 'dark' | 'light'
  direction: 'ltr' | 'rtl'
  responsiveFontSizes: boolean
}

export const createTheme = (config: ThemeConfigProps) => {
  let theme = createMuiTheme(baseThemeOptions, config.mode === 'dark' ? darkThemeOptions : lightThemeOptions, {
    direction: config.direction,
  })

  if (config.responsiveFontSizes) {
    theme = MuiResponsiveFontSizes(theme)
  }

  return theme
}
