import React, { FC, ReactNode } from 'react'

// MUI
import { Box } from '@mui/material'

// WhatsApp FAB
import WhatsAppFAB from '@/components/whatsapp-fab'

// Navbar
import MainNavbar from './navbar'

// Footer
import MainFooter from './footer'

type LayoutProps = {
  children: ReactNode
}

const Layout: FC<LayoutProps> = props => {
  const { children } = props

  return (
    <Box>
      <MainNavbar />

      {children}

      <MainFooter />

      <WhatsAppFAB />
    </Box>
  )
}

export default Layout
