type NavbarLinksProps = {
  label: string
  link: string
}

export const NavbarLinkData: NavbarLinksProps[] = [
  { label: 'Home', link: '/' },
  { label: 'About', link: '/about' },
  { label: 'Gallery', link: '/gallery' },
  { label: 'Services', link: '/services' },
  { label: 'Contact', link: '/contact' },
]
