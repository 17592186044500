import { Box, styled } from '@mui/material'

export const FooterSocialLinkDiv = styled(Box)(({ theme }) => ({
  background: 'transparent',
  padding: '1.5em',
  borderRadius: '50%',
  border: `${theme.typography.pxToRem(1)} solid #DFDFDF33`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

export const PaddTwentyFour = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.typography.pxToRem(24)}`,
}))

export const DividerLine = styled('div')(({ theme }) => ({
  width: theme.typography.pxToRem(50),
  margin: '0 auto',
  borderTop: `${theme.typography.pxToRem(4)} solid #000`,
}))
