// FancyApps
import type { OptionsType } from '@fancyapps/ui/types/Fancybox/options'

// Constants
import { CONSTANTS } from '@/constants'

export const FancyAppsOptions: Partial<OptionsType> = {
  Carousel: {
    infinite: true,
  },
  Hash: false,
  Images: {
    protected: true,
  },
  Toolbar: {
    display: {
      left: ['infobar'],
      middle: [
        'zoomIn',
        'zoomOut',
        'toggle1to1',
        // 'rotateCCW', 'rotateCW', 'flipX', 'flipY'
      ],
      right: [
        'slideshow',
        // 'thumbs',
        'close',
      ],
    },
  },
  caption: CONSTANTS.APP_NAME,
  Thumbs: {
    type: 'classic',
  },
}
