import { Button, styled } from '@mui/material'

// Font
import { LatoFont } from '../fonts'

export const CommonButton = styled(Button)(({ theme }) => ({
  fontFamily: LatoFont.style.fontFamily,
  fontStyle: LatoFont.style.fontStyle,
  fontWeight: LatoFont.style.fontWeight,
  fontSize: theme.typography.pxToRem(20),
  textTransform: 'uppercase',
  backgroundColor: 'var(--primary-color)',
  color: '#FFF',
  padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(59)}`,
  border: `${theme.typography.pxToRem(2)} solid var(--primary-color)`,
  borderRadius: 0,
  transition: 'all 3s ease',

  '&:hover': {
    background: 'transparent',
    color: 'var(--primary-color)',
    border: `${theme.typography.pxToRem(2)} solid var(--primary-color)`,
  },

  '&:disabled': {
    background: '#cccccc',
    color: '#666666',
  },
}))
