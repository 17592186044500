import { createSvgIcon } from '@mui/material'

export const WallPaperIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 503.607 503.607">
    <g>
      <g>
        <path d="M461.64,259.945c-4.196,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S466.676,259.945,461.64,259.945z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M486.82,234.765c-4.196,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S491.856,234.765,486.82,234.765z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M436.46,234.765c-4.197,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S441.496,234.765,436.46,234.765z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M411.279,259.945c-4.196,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S416.315,259.945,411.279,259.945z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M360.919,259.945c-4.197,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S365.955,259.945,360.919,259.945z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M386.099,234.765c-4.196,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S391.135,234.765,386.099,234.765z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M335.737,234.765c-4.196,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S340.773,234.765,335.737,234.765z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M310.557,259.945c-4.196,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S315.594,259.945,310.557,259.945z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M260.196,259.945c-4.196,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S265.232,259.945,260.196,259.945z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M285.378,234.765c-4.197,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S290.414,234.765,285.378,234.765z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M461.64,209.585c-4.196-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C470.033,212.942,466.676,209.585,461.64,209.585z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M461.64,159.224c-4.196,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S466.676,159.224,461.64,159.224z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M486.82,184.404c-4.196,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S491.856,184.404,486.82,184.404z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M436.46,184.404c-4.197,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S441.496,184.404,436.46,184.404z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M411.279,209.585c-4.196-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C419.672,212.942,416.315,209.585,411.279,209.585z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M360.919,209.585c-4.197-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C369.312,212.942,365.955,209.585,360.919,209.585z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M411.279,159.224c-4.196,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S416.315,159.224,411.279,159.224z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M360.919,159.224c-4.197,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S365.955,159.224,360.919,159.224z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M386.099,184.404c-4.196,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S391.135,184.404,386.099,184.404z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M335.737,184.404c-4.196,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S340.773,184.404,335.737,184.404z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M310.557,209.585c-4.196-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C318.951,212.942,315.594,209.585,310.557,209.585z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M260.196,209.585c-4.196-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C268.589,212.942,265.232,209.585,260.196,209.585z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M310.557,159.224c-4.196,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S315.594,159.224,310.557,159.224z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M260.196,159.224c-4.196,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S265.232,159.224,260.196,159.224z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M285.378,184.404c-4.197,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S290.414,184.404,285.378,184.404z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M461.64,108.863c-4.196,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S466.676,108.863,461.64,108.863z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M486.82,134.044c-4.196-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S491.856,134.044,486.82,134.044z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M436.46,134.044c-4.197-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S441.496,134.044,436.46,134.044z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M486.82,83.683c-4.196,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S491.856,83.683,486.82,83.683z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M436.46,83.683c-4.197,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S441.496,83.683,436.46,83.683z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M411.279,108.863c-4.196,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S416.315,108.863,411.279,108.863z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M360.919,108.863c-4.197,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S365.955,108.863,360.919,108.863z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M386.099,134.044c-4.196-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S391.135,134.044,386.099,134.044z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M335.737,134.044c-4.196-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S340.773,134.044,335.737,134.044z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M386.099,83.683c-4.196,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S391.135,83.683,386.099,83.683z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M335.737,83.683c-4.196,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S340.773,83.683,335.737,83.683z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M310.557,108.863c-4.196,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S315.594,108.863,310.557,108.863z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M260.196,108.863c-4.196,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S265.232,108.863,260.196,108.863z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M285.378,134.044c-4.197-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S290.414,134.044,285.378,134.044z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M285.378,83.683c-4.197,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S290.414,83.683,285.378,83.683z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M461.64,58.503c-4.196-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S466.676,58.503,461.64,58.503z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M461.64,8.142c-4.196,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C470.033,11.499,466.676,8.142,461.64,8.142z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M486.82,33.322c-4.196,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C495.213,36.679,491.856,33.322,486.82,33.322z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M436.46,33.322c-4.197,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C444.853,36.679,441.496,33.322,436.46,33.322z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M411.279,58.503c-4.196-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S416.315,58.503,411.279,58.503z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M360.919,58.503c-4.197-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S365.955,58.503,360.919,58.503z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M411.279,8.142c-4.196,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C419.672,11.499,416.315,8.142,411.279,8.142z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M360.919,8.142c-4.197,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C369.312,11.499,365.955,8.142,360.919,8.142z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M386.099,33.322c-4.196,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C394.492,36.679,391.135,33.322,386.099,33.322z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M335.737,33.322c-4.196,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C344.13,36.679,340.773,33.322,335.737,33.322z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M310.557,58.503c-4.196-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S315.594,58.503,310.557,58.503z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M260.196,58.503c-4.196-0.001-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 S265.232,58.503,260.196,58.503z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M310.557,8.142c-4.196,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C318.951,11.499,315.594,8.142,310.557,8.142z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M260.196,8.142c-4.196,0-8.393,4.196-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C268.589,11.499,265.232,8.142,260.196,8.142z" />
      </g>
    </g>
    <g>
      <g>
        <path d="M285.378,33.322c-4.197,0-8.393,4.197-8.393,8.393c0,5.036,3.357,8.393,8.393,8.393s8.393-3.357,8.393-8.393 C293.771,36.679,290.414,33.322,285.378,33.322z" />
      </g>
    </g>
    <g transform="translate(1)">
      <g>
        <path d="M502.607,8.393c0-5.036-3.357-8.393-8.393-8.393h-243.41h0H7.393C2.357,0-1,3.357-1,8.393v486.821 c0,5.036,3.357,8.393,8.393,8.393h243.41c5.036,0,8.394-3.358,8.393-8.395v-100.72H460.64c23.502,0,41.967-26.02,41.967-58.754 c0,0,0,0,0-0.001c0,0,0,0,0,0V8.393z M259.198,16.787H485.82v271.718c-2.291-2.383-4.772-4.432-7.402-6.125 c-0.385-0.249-0.77-0.496-1.162-0.728c-0.242-0.143-0.489-0.275-0.734-0.412c-0.328-0.184-0.656-0.367-0.988-0.539 c-0.311-0.162-0.626-0.314-0.941-0.466c-0.249-0.119-0.497-0.237-0.748-0.35c-0.378-0.171-0.758-0.336-1.141-0.493 c-0.154-0.063-0.309-0.121-0.464-0.182c-1.959-0.768-3.98-1.344-6.052-1.716c-0.208-0.038-0.415-0.075-0.623-0.109 c-0.371-0.059-0.742-0.112-1.116-0.157c-0.336-0.042-0.673-0.074-1.01-0.105c-0.283-0.025-0.566-0.054-0.851-0.072 c-0.649-0.041-1.298-0.067-1.948-0.067h-0.001H259.198V16.787z M217.229,377.704c-7.973,0-15.355-6.806-20.037-17.079 c-3.203-7.029-5.143-15.682-5.143-24.888c0-4.957,0.562-9.754,1.581-14.223c3.64-15.961,13.106-27.744,23.599-27.744h33.551 c0.008,0,0.015,0.001,0.023,0.001h180.275c-7.691,10.62-12.406,25.434-12.406,41.967c0,16.533,4.715,31.346,12.405,41.966H217.229 z M242.411,486.82H15.787V16.787H242.41v260.197h-25.18h-0.001c-23.501,0-41.967,26.02-41.967,58.754s18.465,58.754,41.967,58.754 h0.001h25.18V486.82z M460.64,377.704c-7.973,0-15.355-6.806-20.037-17.079c-3.203-7.029-5.143-15.682-5.143-24.888 c0-4.957,0.562-9.754,1.581-14.223c3.637-15.95,13.094-27.727,23.577-27.743h0.023c9.088,0,17.403,8.848,21.832,21.589 c2.115,6.085,3.348,13.053,3.347,20.377C485.82,358.399,474.07,377.704,460.64,377.704z" />
      </g>
    </g>
  </svg>,
  'WallPaperIcon',
)
