import React, { FC, Fragment } from 'react'

// MUI
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material'

// Data
import { NavbarLinkData } from '@/data'

// UI
import { BlackNextLink, NavbarTypography } from '@/ui'
import { CONSTANTS } from '@/constants'

// Sidebar Drawer Width
const drawerWidth = 250

type MobileNavbarProps = {
  mobileOpen: boolean
  handleDrawerToggle: () => void
}

const MobileNavbar: FC<MobileNavbarProps> = props => {
  const { handleDrawerToggle, mobileOpen } = props

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
      <List>
        {NavbarLinkData.map((data, idx) => {
          return (
            <Fragment key={idx}>
              <ListItem disablePadding>
                <ListItemButton sx={{ ml: 2 }}>
                  <BlackNextLink href={data?.link || '/'} aria-label={`${data?.label} ${CONSTANTS.APP_NAME}`} aria-labelledby={`${data?.label} ${CONSTANTS.APP_NAME}`} role="link">
                    <ListItemText primary={<NavbarTypography>{data?.label}</NavbarTypography>} />
                  </BlackNextLink>
                </ListItemButton>
              </ListItem>
              <Divider />
            </Fragment>
          )
        })}
      </List>
    </Box>
  )

  return (
    <Box component="nav" sx={{ mb: 1 }}>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          zIndex: 99999,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default MobileNavbar
